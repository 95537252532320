import React from 'react';

import '../styles/tabs.sass';

export const Tabs = ( props ) => {
    const { action, selected } = props
    return(
        <nav className='bottom-nav'>
            {
                [{name: 'home', tag: 'home'}, {name: 'mapa', tag: 'map'}, {name: 'otwarcie', tag: 'hours'}, {name: 'kontakt', tag: 'contact'}].map((button,index) => 
                    <Button name={button.name} tag={button.tag} action={action} selected={ selected === button.tag ? true : false } key={`${button.tag}-${index}`} />
                )
            }
        </nav>
    )
}

const Button = (props) => {
    const { name, tag, action, selected } = props
    return(
        <label className={ selected ? 'selected' : null } >
            <div><i className={`icon-${tag}`}></i></div>
            <span>{name} {selected}</span>
            <input type='button' onClick={action} value={tag} />
        </label>
    )
}

export default Tabs;