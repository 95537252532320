import { useStaticQuery, graphql } from "gatsby"
export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            name,
            subname,
            about,
            albums {
                id
                link
                picture
                alt_text
            },
            location {
                city
                country
                latitude
                longitude
                street
                zip
              },
            hours {
                open
                close
                name
              },
            phone,
            username,
            email,
            products,
            id
          }
        }
      }
    `
  )
  return site.siteMetadata
}