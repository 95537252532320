import React, { useState, useEffect } from 'react';
import '../styles/aside.sass';

import { useSiteMetadata } from '../components/use-site-metadata'

export const Aside = ( props ) => {
    const { phone, hours, location, id, username, email } = useSiteMetadata()
    const { animate, selected, action } = props
    return(
        <aside>
            <img src={'/logo.png'} alt="logo" />
            <hr />
            <ul>
                <label className={ selected ? null : 'hide' } >
                    <div></div>
                    <input type='button' onClick={action} />
                </label>
                <li id='map' className={ `${ animate === 'map' ? 'animate' : null } ${ selected === 'map' ? 'selected' : null }` }>
                    <h1>Mapa</h1>
                    <p>
                        <span>{location.street}</span>
                        <span>{location.zip} {location.city}</span>
                    </p>
                    <div className="gmap_canvas">
                        <iframe title='map' width="100%" height="250" id="gmap_canvas" src={`https://maps.google.com/maps?q=${location.latitude},${location.longitude}&t=&z=15&ie=UTF8&iwloc=&output=embed`} frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0">
                        </iframe>
                    </div>
                </li>
                <hr />
                <li id='hours' className={ `${ animate === 'hours' ? 'animate' : null } ${ selected === 'hours' ? 'selected' : null }` }>
                    <h1>Godziny otwarcia</h1>
                    {
                        hours.map((data, index) => OpeningHoursElement(data, index) )
                    }
                </li>
                <hr />
                <li id='contact' className={ `${ animate === 'contact' ? 'animate' : null } ${ selected === 'contact' ? 'selected' : null }` }>
                    <h1>Kontakt</h1>
                    <ContactLink type='tel' link={phone} content={`${phone.substr(0,3)} ${phone.substr(3,3)} ${phone.substr(6,3)} ${phone.substr(9,3)}`} icon='phone' />
                    <ContactLink link={`https://facebook.com/${id}`} content={`facebook.com/${username}`} icon='facebook' />
                    <ContactLink type='mailto' link={email} content={email} icon='mail' />
                </li>
            </ul>
        </aside>
    )
}

function OpeningHoursElement(data, index){
    const { name, open, close } = data
    const [ date, setDate ] = useState()
    const updateDate = () => {
        const newDate = new Date()
        setDate( newDate.getDay() )
    }
    useEffect(() => {
        const timer = setInterval(()=>{
            updateDate();
        }, 1)
        return () => { 
            clearInterval(timer)
        }
    }, [] );
    return(
        <p key={index} className={ index === date - 1 || ( date === 0 && index === 6 ) ? 'current' : null }>
            <b>{name}</b>
            { open && close ?
                <span>
                    <span>{open}</span> - <span>{close}</span>
                </span>
            :
                <span>
                    zamknięte
                </span>
            }
        </p>
    )
}

const ContactLink = (props) => {
    const { type, link, content, icon } = props
    return(
        <a href={`${type ? `${type}:` : ''}${link}`} target="_blank" rel="noopener noreferrer">
            <span>
                <i className={`icon-${icon}`}></i>
            </span>
            {content}
        </a>
    )
}

export default Aside;