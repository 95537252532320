import React from 'react';

export const Navigation = ( props ) => {
    const elements = [
            {
                name: 'Start',
                link: 'start'
            },
            {
                name: 'mapa',
                link: 'map'
            },
            {
                name: 'godziny otwarcia',
                link: 'hours'
            },
            {
                name: 'kontakt',
                link: 'contact'
            },
            {
                name: 'o firmie',
                link: 'about'
            },
            {
                name: 'galeria',
                link: 'gallery'
            },
            {
                name: 'usługi',
                link: 'services'
            }
        ]
    const { action } = props
    return(
        <nav>
            { elements.map((element, index) => navElement(element, index, action) ) }
        </nav>
    )
}

const navElement = (content, key, action) => {
    return(
        <a key={key} href={`#${content.link}`} rel="noopener noreferrer" >
            <label>
                <input type='button' onClick={ action } value={content.link} />
                {content.name}
            </label>
        </a>
    )
}

export default Navigation;