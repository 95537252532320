import React, { useState, useEffect } from "react"

import Aside from '../components/aside'
import Navigation from '../components/navigation'
import Footer from '../components/footer'
import Section from '../components/section'
import Tabs from '../components/tabs'
import { useSiteMetadata } from '../components/use-site-metadata'

import '../styles/styles.sass'
import '../styles/hero.sass'
import '../styles/index.css'

import "../../static/fontello/css/fontello.css"

export default () => {
    const { about, albums, products, name, subname, id } = useSiteMetadata();
    const [ animation, setAnimation ] = useState(null)
    const [ menu, setMenu ] = useState(false)
    const [ information, setInformation ] = useState('home')
    useEffect(() => {
        document.title = `${name} - ${subname}`
    })
    const animate = (e) => {
        const value = e.target.value;
        if( animation !== value ){
            setAnimation( value )
            setTimeout(() => {
                if( animation === value ){
                    setAnimation( null )
                }
            }, 4000)
        }
    }
    const toggleMenu = (e) => {
        setMenu( !menu )
        if( !menu ){
            document.body.style.overflow = 'hidden'
        }else{
            document.body.style.overflow = 'auto'
        }
    }
    const showInformation = (e) => {
        const value = e.target.value;
        if( information === value ){
            setInformation( 'home' )
        }else{
            setInformation(value)
        }
    }
    return (
        <>
            <main id='App' className={ information !== 'home' ? 'block-scroll' : null } >
                <header>
                    <div className='content'>
                        <label className='toggle-menu'>
                            <div><i className="icon-menu"></i></div>
                            <input onClick={ toggleMenu } type='button' />
                        </label>
                        <div className={`menu ${ menu ? null : 'hide' }`} >
                            <header>
                                <label className='toggle-menu'>
                                    <i className="icon-cancel"></i>
                                    <input onClick={ toggleMenu } type='button' />
                                </label>
                                <h1>{name}</h1>
                                <h2>{subname}</h2>
                            </header>
                            <Navigation action={animate} />
                        </div>
                        <img src='/logo.png' alt='logo' />
                    </div>
                    <div className='background' style={{backgroundImage: `URL(background.png)`}}></div>
                </header>
                <Aside selected={information} animate={animation} action={showInformation} />
                <Section type={{section: 1, article: 1}} header={{id: 'about', name: 'o firmie', description: 'czym jest tkt serwis?'}} content={about} animate={animation} />
                <Section type={{section: 2, article: 1, vertical: true, images: true}} header={{id: 'gallery', name: 'GALERIA', description: 'ostatnio dodane zdjęcia'}} content={albums} link={`https://www.facebook.com/${id}/photos`} animate={animation} />
                <Section type={{section: 2, article: 2}} header={{id: 'services', name: 'USŁUGI', description: 'ZAKRES ŚWIADCZONYCH USŁUG'}} content={products} animate={animation} />
            </main>
            <Footer action={animate} />
            <Tabs action={showInformation} selected={information} />
        </>
    )
}