import React from 'react';

import Navigation from '../components/navigation';
import '../styles/footer.sass';

import { useSiteMetadata } from '../components/use-site-metadata'

export const Footer = ( props ) => {
    const { email, phone, id, hours, location, display_subtext } = useSiteMetadata()
    const { action } = props
    return(
        <footer>
            <div>
                <Navigation action={action} />
            </div>
            <div>
                <h1>Kontakt</h1>
                <p>
                    <span>Telefon:</span>
                    <span>{phone}</span>
                </p>
                <p>
                    <span>facebook: </span>
                    <span>{`facebook.com/${id}`}</span>
                </p>
            </div>
            <div>
                <h1>godziny otwarcia</h1>
                {
                    hours.map((element, index)=>
                        <p key={index}>
                            <span>{element.name}</span>
                            <span>{element.open} - {element.close}</span>
                        </p>
                    )
                }
            </div>
            <div>
                <h1>Adres</h1>
                <p>
                    <span>{location.street}</span>
                    <span>{location.zip} {location.city}</span>
                    <span>{location.country}</span>
                </p>
            </div>
            <p>
                Projekt i wykonanie <a href='mailto:oskar.blazejewicz@wp.pl' target="_top">Oskar Błażejewicz</a>, © 2020
            </p>
        </footer>
    )
}

export default Footer;