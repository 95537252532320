import React from 'react';

import '../styles/section.sass'

export const Section = ( props ) => {
    const scroll = (e) => {
        const parent = e.target.parentElement.parentElement.parentElement.nextSibling
        const width = parent.offsetWidth
        const move = ( width > 250 ? ( width / 1.2 ) : 250 )
        const scroll = parseInt( e.target.value + ( move > 800 ? 800 : move ) )
        if( ( parent.scrollLeft + scroll ) <= 0 ){
            e.target.parentElement.parentElement.firstChild.firstChild.disabled = true
        }else{
            e.target.parentElement.parentElement.firstChild.firstChild.disabled = false
        }
		e.target.parentElement.parentElement.parentElement.nextSibling.scrollBy({
			top: 0,
			left: scroll,
			behavior: 'smooth'
        })
	}
    const { type, header, content, link, animate } = props
    return(
        <section id={header.id} className={`section-${type.section}`}>
            <article className={`${ header.id === animate ? 'animate' : null } article-${type.article}  ${ type.vertical ? 'vertical' : null }`}>
                <header>
                    <h1>{header.name}</h1>
                    <h2>{header.description}</h2>
                    { type.vertical ?
                        <div className='buttons'>
                            <label>
                                <input type="button" value="-" onClick={scroll} />
                                <div> ❮ </div>
                            </label>
                            <label>
                                <input type="button" value="+" onClick={scroll} />
                                <div> ❯ </div>
                            </label>
                        </div>
                        :
                        null
                    }
                </header>
                { typeof content === 'object' ?
                    <ul>
                        { content.length > 0 ?
                            <>
                                {content.map((element, index) =>
                                    <li key={index}>
                                        { type.images ? 
                                            <a href={element.link} target='_blank' rel="noopener noreferrer">
                                                {/*console.log( element )*/}
                                                <img src={element.picture} alt={element.alt_text} />
                                            </a>
                                        :
                                            <p>
                                                {element}
                                            </p>
                                        }
                                    </li>
                                )}
                                { type.images ?
                                    <li>
                                        <span>
                                            <a href={link} target='_blank' rel="noopener noreferrer">
                                                <i className='icon-picture'></i>
                                                <span>Zobacz więcej</span>
                                            </a>
                                        </span>
                                    </li>
                                : null }
                            </>
                        :
                            <h3>Brak danych do wyświetlenia.</h3>
                        }
                        
                    </ul>
                :
                    <p>{content}</p>
                }
            </article>
        </section>
    )
}

export default Section;